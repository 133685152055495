<template>
  <b-modal
    id="modal-add-process"
    cancel-title="Отмена"
    ok-title="Сохранить"
    title="Добавление нового шаблона"
    centered
    no-close-on-backdrop
    size="lg"
    @show="openShowModal"
    @ok.prevent="saveBpmGroup"
  >
    <b-form-group label="Название процесса">
      <b-form-input v-model="new_bpm_group.name" />
    </b-form-group>

    <b-form-group
      v-if="editItem.id"
      label="Статус"
    >
      <b-form-checkbox
        v-model="new_bpm_group.status"
        class="mt-50"
        inline
        name="check-button"
        switch
      >
        {{
          new_bpm_group.status
            ? 'Активный'
            : 'Не активен'
        }}
      </b-form-checkbox>
    </b-form-group>

    <b-form-group label="Описание">
      <b-form-textarea
        id="textarea-default"
        v-model="new_bpm_group.description"
        placeholder="Описание"
        class="mb-1"
        rows="3"
      />
    </b-form-group>

    <b-form-group label="Вид кредита">
      <v-select
        v-model="new_bpm_group.credit_type"
        :close-on-select="false"
        :options="[]"
        :reduce="p => p.id"
        label="description"
        multiple
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AddEditBpmGroups',
  components: {
    BFormTextarea,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    VSelect,
  },
  props: {
    editItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      new_bpm_group: {
        name: '',
        description: '',
        credit_type: [],
        status: false,
      },
    }
  },
  computed: {
    ...mapGetters(['getCreditVid']),
  },
  methods: {
    ...mapActions(['FETCH_BPM_GROUPS', 'CREATE_BPM_GROUP', 'UPDATE_BPM_GROUP']),

    async saveBpmGroup() {

    },

    openShowModal() {
      // this.new_bpm_group = JSON.parse(JSON.stringify(this.editItem));
    },
  },
}
</script>
