<template>
  <div>
    <b-button
      v-b-modal:modal-add-process
      size="md"
      variant="primary"
      class="mb-1"
    >
      <feather-icon
        icon="PlusIcon"
      />
      Добавить
    </b-button>

    <b-card>
      <b-table
        :fields="columns"
        :items="[]"
        empty-text="Нет данных"
        show-empty
        responsive
      >
        <template #cell(actions)="props">
          <div class="d-flex align-items-process justify-content-around">
            <b-button
              size="sm"
              variant="success"
              @click="$router.push({
                name: 'soft-collection-process-settings',
                params: {
                  id: props.item.id,
                  group_name: props.item.name
                }
              })"
            >
              <feather-icon
                icon="EyeIcon"
              />
            </b-button>

            <b-button
              v-b-modal.modal-add-process
              size="sm"
              variant="warning"
              class="ml-50"
              @click="editItem = props.item"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>

            <b-button
              v-b-modal.modal-delete-group
              size="sm"
              variant="danger"
              class="ml-50"
              @click="deleteProccess(props.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </div>
        </template>

        <template #cell(index)="props">
          {{ props.index + 1 }}
        </template>

        <template #cell(status)="props">
          <b-form-checkbox
            v-model="props.item.status"
            class="mt-50"
            name="check-button"
            switch
            inline
            disabled
          >
            {{ props.item.status ? 'active' : 'not_active' }}
          </b-form-checkbox>
        </template>

        <!--        <template #cell(start)="props">-->
        <!--          <b-button-->
        <!--            variant="info"-->
        <!--            @click="startBpm(props.item.id)"-->
        <!--          >-->
        <!--            <feather-icon-->
        <!--              icon="PlayIcon"-->
        <!--              size="16"-->
        <!--              class="text-white"-->
        <!--            />-->
        <!--          </b-button>-->
        <!--        </template>-->

        <template #cell(credit_type)="props">
          <span class="d-flex flex-wrap ml-1">
            <b-badge
              v-for="(item, index) in props.item.credit_type"
              :key="index"
              class="mb-50 ml-50"
              variant="primary"
            >
              {{ item.description }}
            </b-badge>
          </span>
        </template>
      </b-table>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="params.page_size"
            :options="[5, 10, 20]"
            class="mx-1"
            @change="refresh"
          />
          <span class="ml-1 text-nowrap"> строк( {{ 10 }} )</span>
        </div>
        <b-pagination
          v-model="params.page"
          :total-rows="10"
          :per-page="params.page_size"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card>

    <addEditModal />
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BBadge,
  BCard,
} from 'bootstrap-vue'
import addEditModal from './addEditModal.vue'

export default {
  components: {
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BBadge,
    BCard,
    addEditModal,
  },
  data() {
    return {
      params: {
        page: 1,
        page_size: 10,
      },
      columns: [
        {
          label: '№',
          key: 'index',
        },
        {
          label: 'НАЗВАНИЕ',
          key: 'name',
        },
        {
          label: 'ОПИСАНИЕ',
          key: 'description',
        },
        {
          label: 'ВИД КРЕДИТА',
          key: 'credit_type',
        },
        {
          label: 'СТАТУС',
          key: 'status',
        },
        // {
        //   label: 'configuration.start',
        //   key: 'start',
        // },
        {
          label: 'ДЕЙСТВИЯ',
          key: 'actions',
        },
      ],
      editItem: {},
    }
  },
  methods: {
    refresh() {
    },
    deleteProccess() {
    },
    changePage() {
    },
  },
}
</script>
